:global {
  html,
  body {
    overflow: hidden;
    margin: 0;
    background-color: #29a4e2 !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
    font-weight: normal;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-weight: normal;
  }

  ul {
    margin: 0;
  }

  body {
    height: 100vh; /* fallback */
    height:
      calc(
        100vh - env(--safe-area-inset-bottom) -
        env(--safe-area-inset-top)
      ) !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
  }

  #menu-root {
    position: fixed;
    top: 0;
    right: 0;
    z-index: var(--z-index-dropdown);
  }

  #root {
    height: 100% !important;
  }
}

@keyframes :global(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
body {
  background-color: #29a4e2;
  color: #fff;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #000;
}

/**
* Define css vars with proper defaults
* Used for theming the whole app
**/
:root {
  /* Main colors */
  --main-color: #dbdbdb;
  --main-text-color: #fff;

  /* Background */
  --background-color: wheat;
  --background-text-color: #fff;

  /* Highlight */
  --highlight-color: #fc0;
  --highlight-text-color: #000;

  /* Favorite Icon color */
  --favorite-color: #c80000;

  /* Transition variables */
  --transition-duration-low: 0.3s;
  --transition-duration-medium: 0.6s;
  --transition-duration-high: 1s;

  /* Z-index variables */
  --z-index-scrollTrack: 600;
  --z-index-scrollThumb: 601;
  --z-index-modal: 500;
  --z-index-drawer: 400;
  --z-index-dropdown: 300;
  --z-index-header: 200;
  --z-index-backdrop: -100;
  --template-blue: #29a4e2;
}

:not(:root):fullscreen::backdrop {
  background: rgb(255 255 255 / 0) !important;
}

:not(:root):fullscreen {
  overflow: auto !important;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: #ffffff;
  }
}
 a{
   text-decoration: none;
 }